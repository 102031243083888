import {APIError} from "client";

export interface IMetaStore {
	token?: any;
	loadingCount: number;
	errors: Array<APIError>;
	sidebarOpen: boolean;
}

export default {
	loadingCount: 0,
	errors: [],
	sidebarOpen: false,
} as IMetaStore
