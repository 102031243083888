import React from "react";
import "./App.css";
import {connect} from "react-redux";
import {persistor} from "./redux";
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";
import Loader from "./components/Loader";
import ErrorModalHandler from "./components/ErrorModalHandler";
import Home from "./pages/Dashboard";
import AuthenticatedRoute from "./pages/AuthenticatedRoute";
import {IStore} from "./redux/defaultStore";
import Header from "./components/Header";
import FAQPage from "./pages/FAQPage";
import Dashboard from "./pages/Dashboard";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";

/* Put regular Routes & header / foots etc as children inside of SidebarHelper below,
for absolutes like Loading, errors, etc, make them siblings SidebarHelper */
const t = 1;
const App: React.FC = () => {

	return (
		<PersistGate persistor={persistor}>
			<BrowserRouter>

				<ErrorModalHandler/>
				<Loader/>
				<Header/>

				<main>
					<Switch>
						<Route exact path="/" component={HomePage}/>
						<Route exact path="/log-in" component={Login}/>
						{/*<Route exact path="/sign-up" component={Register}/>*/}
						<Route exact path="/faq" component={FAQPage}/>
						<Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
						<Route exact path="/terms-of-service" component={TermsOfServicePage}/>
						<AuthenticatedRoute exact path="/dashboard" component={Dashboard}/>

						<Route exact path="/404" component={NotFound}/>
						<Redirect to="/404"/>
					</Switch>
				</main>

				<Footer/>
			</BrowserRouter>
		</PersistGate>
	);
};

export default connect((store: IStore, props: any) => {
	return {
		sidebarOpen: store.metaStore.sidebarOpen,
		...props,
	}
})(App);
