import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading, logout} from "../redux/meta/MetaActions";
import {
	Article,
	GetMarketResponse,
	Market,
	MarketApi,
	MarketIdentifier,
	RealestateSecuritiesApi,
	UserApi
} from "client";
import {getConfig} from "../services/clientApis";
import SecurityMarketListItem from "../components/SecurityMarketListItem";
import JoinMarketSuccessModal from "../components/JoinMarketSuccessModal";

interface IHomeProps {
	token?: string;
	dispatch?: any;
}

const Dashboard: React.FC<IHomeProps> = (props: IHomeProps) => {

	const {token} = props;
	const [markets, setMarkets] = useState<Array<GetMarketResponse>>();
	const [marketJustJoined, setMarketJustJoined] = useState<Market>();

	useEffect(() => {
		readMarkets().then().catch();
	}, []);

	/**
	 * Utility for the api calls for reading info for each market
	 *
	 * @param mIdentifier
	 */
	async function marketGetFactory(mIdentifier: MarketIdentifier): Promise<any> {
		try {
			return await new MarketApi(getConfig(token)).marketGetMarketGet({marketIdentifier: mIdentifier});
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}
	}

	/**
	 * Constructs a promise array to call to get info on all the markets.
	 * To add more, "promises.push(marketGetFactory(MarketIdentifier.x));"
	 *
	 */
	async function readMarkets(): Promise<void> {
		props.dispatch(incrementLoading());
		const promises: Array<Promise<any>> = [];
		promises.push(marketGetFactory(MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver));

		const res = await Promise.all(promises);
		if (res) {
			setMarkets(res);
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Calls api for the user to join the chosen market.
	 * Once joined, show success modal.
	 *
	 * @param market
	 */
	async function joinMarket(market: Market): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new MarketApi(getConfig(token)).marketJoinPost({marketIdentifierRequest: {marketIdentifier: market.marketIdentifier}});
			setMarketJustJoined(market);
			await readMarkets();
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Iterate the markets and return a component for each
	 *
	 * @param ms
	 */
	function mapMarkets(ms: Array<GetMarketResponse> = []): ReactNode {
		return ms.map((m: GetMarketResponse, i: number) => {

			function joinHelper(): void {
				joinMarket(m.market).then().catch();
			}

			return (
				<Col xs={12} sm={12} md={6} lg={6} key={`security-market-${i}`} className="mb-4">
					<SecurityMarketListItem
						market={m.market}
						tradingProfile={m.tradingProfile}
						onJoinMarket={joinHelper}
					/>
				</Col>
			)
		});
	}

	function handleCloseJoinSuccessModal(): void {
		setMarketJustJoined(null);
	}

	return (
		<React.Fragment>
			<JoinMarketSuccessModal market={marketJustJoined} onClose={handleCloseJoinSuccessModal}/>

			<Container className="my-5">
				<div className="mb-4">
					<h2>Security Markets</h2>
					<p>
						This is a list of security markets that you are able to participate in.
						To join a new market press the "Join Market" button for the one you would like to join.
						If you are already a part of the market, click "Go to Market" to visit that market instead.
					</p>
				</div>

				<Row className="flex-wrap">
					{mapMarkets(markets)}
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IHomeProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(Dashboard);
