import {IStore} from "../redux/defaultStore";
import {APIError} from "client";
import React, {ReactNode, useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {removeErr} from "../redux/meta/MetaActions";
import {connect} from "react-redux";

interface IProps {
	errors?: APIError[]
}

const ErrorModalHandler: React.FC<IProps> = ({errors}) => {
	const modals: ReactNode[] = errors.map((error, i): ReactNode => {
		return <ErrorModal key={"error_" + i} error={error} index={i}/>
	});

	return <>{modals}</>;
};

ErrorModalHandler.defaultProps = {
	errors: []
};

// Error Modal helper class
const _ErrorModal: React.FC<{error: APIError, index: number, dispatch: any}> = ({error, index, dispatch}) => {

	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!open) {
			setOpen(true)
		}
	}, [JSON.stringify(error), index]);

	function makeText(text: string, i: number) {
		return <p key={"message_" + i}>{text}</p>
	}

	function dismiss() {
		setOpen(false);
		dispatch(removeErr(index));
	}

	function contactSupport() {

	}

	return (
		<Modal isOpen={open} centered={true}>
			<ModalHeader>Error!</ModalHeader>
			<ModalBody>
				{error.messages.map(makeText)}
			</ModalBody>
			<ModalFooter>
				{/*<Button color="secondary" onClick={contactSupport}>Contact Support</Button>{' '}*/}
				<Button color="primary" onClick={dismiss}>Dismiss</Button>
			</ModalFooter>
		</Modal>
	)
};

const ErrorModal = connect()(_ErrorModal);

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		errors: store.metaStore.errors,
	}
})(ErrorModalHandler);
