import React from "react";
import {Button, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {Market} from "client";

interface IJoinMarketSuccessModalProps {
	market: Market;

	onClose(): void;
}

const JoinMarketSuccessModal: React.FC<IJoinMarketSuccessModalProps> = (props: IJoinMarketSuccessModalProps) => {

	const {market} = props;

	return (
		<Modal
			isOpen={market && Object.keys(market).length > 0}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={props.onClose}
		>
			{market && (
				<React.Fragment>
					<ModalHeader toggle={props.onClose}>Market Joined Successfully</ModalHeader>

					<div className="my-3">
						{`You have just joined the ${market.name} market! You can access the market at: `}
						<a href={market.url} target="_blank">
							{market.url}
						</a>
						{` , or by clicking the appropriate "Go to Market" button on this page.`}
					</div>

					<ModalFooter>
						<Button color="success" onClick={props.onClose}>
							Done
						</Button>
					</ModalFooter>
				</React.Fragment>
			)}

		</Modal>
	)
};

export default JoinMarketSuccessModal;
