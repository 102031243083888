import React from "react";
import {Container} from "reactstrap";

const PrivacyPolicyPage: React.FC = () => {

	return (
		<Container className="my-5">
			Privacy Policy Page
		</Container>
	);
};

export default PrivacyPolicyPage;
