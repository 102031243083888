import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {
	Button,
	Collapse, Container,
	Nav,
	Navbar,
	NavbarBrand, NavbarToggler,
	NavItem,
} from "reactstrap";
import {FiLogIn, FiLogOut, FiMenu, FiUserPlus} from "react-icons/fi";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {logout} from "../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";

interface IHeaderProps {
	token?: string;
	dispatch?: any;
	sidebarOpen?: boolean;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {

	const {token} = props;
	const [isOpen, updateIsOpen] = useState(false);
	const history = useHistory();

	/**
	 * Toggle the mobile menu being visible
	 *
	 */
	function toggleIsOpen(): void {
		updateIsOpen(!isOpen);
	}

	function mobileCloseHandler(): void {
		if (isOpen) {
			toggleIsOpen();
		}
	}

	function logOutHelper(e: React.MouseEvent<HTMLAnchorElement>): void {
		e.preventDefault();
		mobileCloseHandler();
		history.push("/log-in");
		props.dispatch(logout());
	}

	return (
		<div className="border-bottom">
			<Navbar color="light" light expand="md">
				<Container>

					<Nav navbar>
						<NavItem>
							<NavLink exact to="/" className="text-decoration-none nav-link navbar-brand">
								<img
									src={process.env.PUBLIC_URL + "/logos/logo_icon.png"}
									style={{height: 40}}
								/>
							</NavLink>
						</NavItem>
					</Nav>

					<NavbarToggler onClick={toggleIsOpen} aria-label="View Navigation"
					               className="hamburger-helper border-0">
						<FiMenu
							size="2rem"
							style={{maxHeight: 50}}
						/>
					</NavbarToggler>

					<Collapse isOpen={isOpen} navbar>
						<Nav className="ml-auto d-flex align-items-center" navbar>

							<NavItem>
								<NavLink exact to="/" className="text-decoration-none nav-link"
								         onClick={mobileCloseHandler}>
									Home
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink exact to="/faq" className="text-decoration-none nav-link"
								         onClick={mobileCloseHandler}>
									FAQ
								</NavLink>
							</NavItem>

							<span className="d-none d-md-inline-block text-muted mx-2"
							      style={{fontSize: "1.5rem"}}>
									|
							</span>

							<div className="w-100 d-block d-md-none d-flex justify-content-center">
								<div style={{maxWidth: 160}} className="w-100">
									<hr/>
								</div>
							</div>

							<NavItem>
								<a className="text-decoration-none nav-link" href={process.env.REACT_APP_FRONTEND_URL} target="_blank">
									Vancouver Play Money Market
								</a>
							</NavItem>

							{/*{!token && (*/}
							{/*	<React.Fragment>*/}
							{/*		<NavItem>*/}
							{/*			<NavLink exact to="/log-in"*/}
							{/*			         className="text-decoration-none nav-link d-flex align-items-center"*/}
							{/*			         onClick={mobileCloseHandler}>*/}
							{/*				<FiLogIn*/}
							{/*					size="1.25rem"*/}
							{/*					style={{maxHeight: 50}}*/}
							{/*					className="mr-2"*/}
							{/*				/>*/}
							{/*				Log in*/}
							{/*			</NavLink>*/}
							{/*		</NavItem>*/}

							{/*		<NavItem>*/}
							{/*			<NavLink exact to="/sign-up"*/}
							{/*			         className="text-decoration-none nav-link d-flex align-items-center"*/}
							{/*			         onClick={mobileCloseHandler}>*/}
							{/*				<FiUserPlus*/}
							{/*					size="1.25rem"*/}
							{/*					style={{maxHeight: 50}}*/}
							{/*					className="mr-2"*/}
							{/*				/>*/}
							{/*				Sign up*/}
							{/*			</NavLink>*/}
							{/*		</NavItem>*/}
							{/*	</React.Fragment>*/}
							{/*)}*/}

							{/*{token && (*/}
							{/*	<React.Fragment>*/}
							{/*		<span className="d-none d-md-inline-block text-muted mx-2"*/}
							{/*		      style={{fontSize: "1.5rem"}}>*/}
							{/*			|*/}
							{/*		</span>*/}

							{/*		<div className="w-100 d-block d-md-none d-flex justify-content-center">*/}
							{/*			<div style={{maxWidth: 160}} className="w-100">*/}
							{/*				<hr/>*/}
							{/*			</div>*/}
							{/*		</div>*/}

							{/*		<NavItem>*/}
							{/*			<NavLink exact to="/dashboard" className="text-decoration-none nav-link"*/}
							{/*			         onClick={mobileCloseHandler}>*/}
							{/*				Dashboard*/}
							{/*			</NavLink>*/}
							{/*		</NavItem>*/}

							{/*		<NavItem>*/}
							{/*			<Button*/}
							{/*				color="link"*/}
							{/*				className="text-decoration-none nav-link text-danger d-flex align-items-center"*/}
							{/*				onClick={logOutHelper} href="#">*/}
							{/*				<FiLogOut*/}
							{/*					size="1.25rem"*/}
							{/*					style={{maxHeight: 50}}*/}
							{/*					className="mr-2"*/}
							{/*				/>*/}
							{/*				Log out*/}
							{/*			</Button>*/}
							{/*		</NavItem>*/}
							{/*	</React.Fragment>*/}
							{/*)}*/}
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default connect((store: IStore, props: IHeaderProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(Header);
