import React from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Market, TradingProfile} from "client";

interface ISecurityMarketListItemProps {
	market: Market;
	tradingProfile?: TradingProfile;

	onJoinMarket(): void;
}

const SecurityMarketListItem: React.FC<ISecurityMarketListItemProps> = (props: ISecurityMarketListItemProps) => {

	const {market, tradingProfile} = props;

	return (
		<Card style={{borderRadius: 10}} className="w-100 h-100 overflow-hidden d-flex flex-column justify-content-between">
			<Row className="no-gutters">
				<Col xs={12} sm={12} md={12} lg={12}>

					<div style={{width: "100%", height: 260}} className="overflow-hidden">
						<img
							style={{width: "100%", height: "100%", objectFit: "cover"}}
							src={"https://res.cloudinary.com/simpleview/image/upload/v1486505969/clients/vancouverbc/Aerial_Sunset_Vancouver_d3_copy_1bb86ed0-1edc-4cda-841d-0b033ca0bb72.jpg"}
							alt={`${market.name}-thumbnail`}
							className="news-image"
						/>
					</div>
				</Col>

				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="p-3">
						<h3>
							{market.name}
						</h3>
						<p>
							{market.description}
						</p>
					</div>
				</Col>

			</Row>
			<CardBody className="pt-0 d-flex align-items-end">
				<div className="w-100 d-flex justify-content-end">
					{tradingProfile ? (
						<a href={"#"} target="_blank">
							<Button color="success">
								Go to Market
							</Button>
						</a>
					) : (
						<Button color="materialBlue" onClick={props.onJoinMarket}>
							Join Market
						</Button>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

export default SecurityMarketListItem;
