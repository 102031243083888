import React from "react";
import {Button, Container} from "reactstrap";
import {useHistory} from "react-router-dom";

const NotFound: React.FC = () => {

	const history = useHistory();

	function goHome(): void {
		history.push("/");
	}

	return (
		<Container className="my-5">
			<img
				src={process.env.PUBLIC_URL + "/images/404.jpg"}
				alt="404 not found"
				className="w-100"
			/>
			<div className="w-100 d-flex justify-content-center mt-4">
				<Button color="materialBlue" onClick={goHome}>
					Take me Home
				</Button>
			</div>
		</Container>
	);
};

export default NotFound;
