import React from "react";
import "./ShowCaseButton.scss";

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	light?: boolean;
	slim?: boolean;
	onClick?(): void;
	noMargin?: boolean;
}


const ShowcaseButton: React.FC<IProps> = (props) => {

	return (
		<button
			onClick={props.onClick}
			className={(props.light ? "showcase-button-lt " : "showcase-button-dark ") + props.className}
			style={{
				padding: props.slim ? "5px 15px" : "10px 15px",
				justifyContent: "center",
				...props.style,
				margin: props.noMargin === true ? "0px !important" : "10px",
			}}
		>
			{props.children}
		</button>
	);
};

ShowcaseButton.defaultProps = {
	light: true,
	slim: false,
	className: "",
	onClick: null,
	noMargin: false,
};

export default ShowcaseButton
