import React from "react";
import {Col, Container, Nav, Navbar, NavItem, Row} from "reactstrap";
import {Link, NavLink} from "react-router-dom";
import {FiMail, FiPhone} from "react-icons/fi";

const Footer: React.FC = () => {

	const t = 1;

	return (
		<div className="border-top">
			<Container className="py-4">
				<Row>
					<Col xs={12} sm={6} className="mb-3 mb-sm-0">
						<h5>Legal</h5>

						<Nav className="ml-auto d-flex" navbar vertical>
							<NavItem className="d-flex">
								<NavLink exact to="/privacy-policy" className="text-decoration-none nav-link text-secondary">
									Privacy Policy
								</NavLink>
							</NavItem>

							<NavItem className="d-flex">
								<NavLink exact to="/terms-of-service" className="text-decoration-none nav-link text-secondary">
									Terms of Service
								</NavLink>
							</NavItem>
						</Nav>
					</Col>

					<Col xs={12} sm={6} className="mb-3 mb-sm-0">
						<h5>Momo Deretic</h5>

						<Nav className="ml-auto d-flex" navbar vertical>
							<NavItem className="d-flex">
								<a className="nav-link text-secondary" href="tel:6043587349">
									<FiPhone
										size="1rem"
										style={{maxHeight: 50}}
										className="mr-2"
									/>
									604 358 7349
								</a>
							</NavItem>

							<NavItem className="d-flex">
								<a className="nav-link text-secondary" href="mailto:momo@jazmodesoftware.com?subject=Security Trading Software" target="_blank">
									<FiMail
										size="1rem"
										style={{maxHeight: 50}}
										className="mr-2"
									/>
									momo@jazmodesoftware.com
								</a>
							</NavItem>
						</Nav>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Footer;
