import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {LoginBody, MarketIdentifier, UserApi} from "client";
import {addError, decrementLoading, incrementLoading, login} from "../redux/meta/MetaActions";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {NavLink, useHistory} from "react-router-dom";

const defaultLoginForm: Partial<LoginBody> = {
	email: "",
	password: "",
};

interface ILoginProps {
	token?: string;
	dispatch?: any;
}

const Login: React.FC<ILoginProps> = (props: ILoginProps) => {

	const {token, dispatch} = props;
	const [form, setForm] = useState<Partial<LoginBody>>(defaultLoginForm);
	const history = useHistory();

	useEffect(() => {
		if (token) {
			history.replace("/dashboard");
		}
	}, []);

	function createOnChange(key: keyof LoginBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({...form, [key]: e.target.value});
		}
	}

	async function submitLogin(): Promise<void> {
		dispatch(incrementLoading());
		try {
			const res = await new UserApi().userFormsLoginPost({loginBody: {...form, marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver} as LoginBody});
			props.dispatch(login(res.token));
			history.push("/dashboard");
		} catch (err) {
			props.dispatch(addError(await err.json()));
		}
		dispatch(decrementLoading());
	}

	return (
		<Row style={{height: "100vh"}}>
			<Col className="d-none d-lg-flex" lg={5}>
				<div className="w-100 h-100 log-in-background-image"/>
			</Col>

			<Col xs={12} lg={7} className="h-100 d-flex flex-column" style={{overflowY: "scroll"}}>
				<div className="px-5 px-lg-0 w-100 w-lg-60" style={{marginTop: "15vh"}}>

					<div className="mb-4">
						<h1 className="mb-3">
							Log in
						</h1>
						<p>
							Log in now to access your securities market dashboard.
						</p>
					</div>

					<div className="my-3">
						<Label for="email">Email</Label>
						<Input type="email" id="email" name="email" placeholder="Email Address"
						       onChange={createOnChange("email")}/>
					</div>

					<div className="my-3">
						<Label for="password">Password</Label>
						<Input type="password" id="password" name="password" placeholder="Password"
						       onChange={createOnChange("password")}/>
					</div>

					<div className="my-4 d-flex flex-column align-items-end">
						<div className="mb-4">
							<Button color="materialBlue" onClick={submitLogin}>
								Log in
							</Button>
						</div>

						<div>
							{"Don't have an account yet? "}<NavLink to="/sign-up" className="ml-1">Sign up instead</NavLink>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default connect((store: IStore, props: ILoginProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(Login);
