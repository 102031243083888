import React from "react";
import {Container} from "reactstrap";
import HomePageHero from "../components/HomePageHero";

const HomePage: React.FC = () => {

	return (
		<div>
			<HomePageHero/>

			<Container className="my-5">
				Home page
			</Container>
		</div>
	);
};

export default HomePage;
