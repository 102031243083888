import React, {ReactNode, useEffect, useState} from "react";
import {Container} from "reactstrap";
import {connect} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {FaqApi, Question} from "client";

interface IFAQPageProps {
	dispatch?: any;
}

const FAQPage: React.FC<IFAQPageProps> = (props: IFAQPageProps) => {

	const [faq, setFaq] = useState<Array<Question>>();

	useEffect(() => {
		readFAQ().then().catch();
	}, []);

	async function readFAQ(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FaqApi().faqListGet();

			setFaq(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function createFAQ(_faq): ReactNode {
		if (!_faq || _faq.length < 1) {
			return (
				<div>
					<p className="text-center font-italic text-muted">
						The FAQ is currently empty.
					</p>
				</div>
			)
		}

		return _faq.map((q: Question, i: number) => {
			return (
				<div key={`faq-item-${i}`} className="mb-4">
					<h2>
						{q.title}
					</h2>
					<p className="text-secondary">
						{q.description}
					</p>
				</div>
			);
		})
	}

	return (
		<Container className="my-5">
			<h1>
				Frequently Asked Questions
			</h1>
			<hr/>

			<div className="my-4">
				{faq ? (
					<div>
						{createFAQ(faq)}
					</div>
				) : (
					<div>
						<p className="text-center font-italic text-muted">
							Loading FAQ...
						</p>
					</div>
				)}
			</div>
		</Container>
	);
};

export default connect()(FAQPage);
