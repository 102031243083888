import React from "react";
import LazyHero from "react-lazy-hero";
import {Container} from "reactstrap";
import ShowcaseButton from "./ShowcaseButton";
import {useHistory} from "react-router-dom";

const HomePageHero: React.FC = () => {

	const history = useHistory();

	function onGetStarted(): void {
		history.push("/sign-up");
	}

	return (
		<LazyHero
			imageSrc={process.env.PUBLIC_URL + "/images/van-skyline2-cropped-min.png"}
			isFixed={true}
			parallaxOffset={50}
			transitionDuration={0}
			minHeight="70vh"
			color={"#161616"}
		>
			<Container>
				<h1 className="text-light mb-4">
					Real Estate Security Trading
				</h1>
				<a href={process.env.REACT_APP_FRONTEND_URL} target="_blank">
					<ShowcaseButton light={false} slim={false}>
						Get Started
					</ShowcaseButton>
				</a>
			</Container>
		</LazyHero>
	);

};

export default HomePageHero;

